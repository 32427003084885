import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { useLocale } from '../hooks/locale'

function useHomeContent() {
    // Grab the locale (passed through context) from the Locale Provider 
    // through useLocale() hook
    const { locale } = useLocale()
    // Query the JSON files
    const { rawData } = useStaticQuery(query)

    // Simplify the response from GraphQL
    const simplified = rawData.edges.map(item => {
        return {
            name: item.node.name,
            homeContent: item.node.translations.homeContent,
        }
    })

    // Only return menu for the current locale
    const { homeContent } = simplified.filter(
        lang => lang.name === locale,
    )[0]

    return homeContent
}

export default useHomeContent

const query = graphql`
  query useHomeContent {
    rawData: allFile(filter: { sourceInstanceName: { eq: "home-content" } }) {
      edges {
        node {
          name
          translations: childHomeContentJson {
            homeContent {
              link
              button
              description
              name
              anchor
              image  {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: TRACED_SVG
                    formats: [AUTO, WEBP]
                  )
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
