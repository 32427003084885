import React from 'react'
import SEO from '../components/seo'
import useTranslations from '../components/useTranslations'
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image'
import useHomeContent from '../components/useHomeContent'
import { Link } from 'gatsby'

const Login = () => {
    const homeContent = useHomeContent()
    const { client_login, email, pwd, lost_pwd, enter } = useTranslations()

    return (
        <>
            <SEO title='Login' />
            <div className='bg-dark min-h-auto flex items-center w-full'>
                <div className='container mx-auto max-w-4xl'>
                    <section className=''>
                        <div className='relative w-full z-10' data-sal="fade" data-sal-delay="300" data-sal-easing="ease">
                            <div className='font-jura bg-tiles bg-opacity-90 py-8 sm:py-16 px-8 mx-4 sm:mx-10'>
                                <h2 className='text-xl mb-6 md:text-5xl text-primary font-bold'>
                                    {client_login}
                                </h2>
                                <div>{email} *</div>
                                <div className="bg-gray-800 w-64 h-8 mb-4"></div>
                                <div>{pwd} *</div>
                                <div className="bg-gray-800 w-64 h-8"></div>
                                <div className="mb-3">{lost_pwd}</div>
                                <div className='pt-4 sm:pt-8'>
                                    <a target="_blank" href='/assets/docs/company-intro.pdf'><button
                                        className='text-lg bg-black text-white h-10 w-40 uppercase bg-opacity-80'>
                                        {enter}
                                    </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className='absolute z-0 left-0 h-full bottom-0 w-full'>
                            <StaticImage className="w-full h-full" src={`../../static/assets/img/viking-cruises.jpg`} alt='BG Image' />
                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}
export default Login;